import { Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { Svg } from '@smooved/ui';

@Component({
    selector: 'app-energy-offer-selected-container',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-flex-column u-flex-align-items-center">
                    <ng-container
                        [ngTemplateOutlet]="cardContent"
                        *ngIf="moveSandbox.energyOfferSelected$ | async"
                        [ngTemplateOutletContext]="{ suggestion: (moveSandbox.energyOfferSelection$ | async) }"
                    ></ng-container>
                </app-card>
            </div>
        </app-card-container>

        <ng-template #cardContent let-suggestion="suggestion">
            <img
                [src]="'assets/images/suppliers/' + suggestion.image"
                [alt]="'Logo ' + 'suggestion.supplier'"
                class="u-margin-bottom-double h-[80px]"
            />

            <div class="u-container-small u-flex-column u-flex-align-items-center">
                <div
                    class="u-flex-row u-flex-align-items-center u-margin-bottom-double u-padding-bottom-double u-bordered-bottom u-w100p u-flex-justify-content-center"
                >
                    <app-check-with-background [width]="22" [height]="22" class="u-margin-right-half"></app-check-with-background>
                    <h5>{{ suggestion.nameLabels | translatedLabel: { fallback: { object: suggestion, label: 'name' } } | async }}</h5>
                </div>

                <h3 class="u-margin-bottom">{{ 'ENERGY.OFFER_SELECTED.TITLE' | translate }}</h3>

                <p class="u-font-size-medium u-margin-bottom-double u-text-align-center">{{ 'ENERGY.OFFER_SELECTED.TEXT' | translate }}</p>

                <app-button theme="secondary" [routerLink]="telecomRoute" class="u-margin-bottom">{{
                    'ENERGY.OFFER_SELECTED.CTA_MAIN.TEXT' | translate
                }}</app-button>

                <a [routerLink]="contractRoute" class="u-link">{{ 'ENERGY.OFFER_SELECTED.CTA_SECONDARY.TEXT' | translate }}</a>
            </div>
        </ng-template>
    `,
})
export class EnergyOfferSelectedContainer {
    public svg = Svg;
    public telecomRoute = `/${Navigation.Telecom}`;
    public contractRoute = `/${Navigation.Contract}`;

    constructor(public moveSandbox: MoveSandbox) {}
}
