import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { EnergyOrderInformationComponent } from '@app/energy/components/energy-order-information/energy-order-information.component';
import { EnergySuggestionSmallComponent } from '@app/energy/components/energy-suggestion-small/energy-suggestion-small.component';
import { MeterReadingsTakeoverDoneComponent } from '@app/energy/components/meter-readings-takeover-done/meter-readings-takeover-done.component';
import { MeterTypeComponent } from '@app/energy/components/meter-type/meter-type.component';
import { ServiceEnergyMeterReadingsComponent } from '@app/energy/components/service-energy-meter-readings/service-energy-meter-readings.component';
import { TakeoverInformationConsentComponent } from '@app/energy/components/takeover-information-consent/takeover-information-consent.component';
import { EanInfoTableActionsColumnComponent } from '@app/energy/modals/ean-info/ean-info-table-actions/ean-info-table-actions.column';
import { EanInfoTableResultColumnComponent } from '@app/energy/modals/ean-info/ean-info-table-result/ean-info-table-result.column';
import { EanInfoModal } from '@app/energy/modals/ean-info/ean-info.modal';
import { ProductConditionPipe } from '@app/energy/pipes/product-condition.pipe';
import { TranslationModule } from '@app/translation/translation.module';
import { PipeModule as CorePipeModule } from '@smooved/core';
import {
    AlertModule,
    InfoModule,
    SvgModule,
    TableModule,
    TrackAndTraceModule,
    EnergyModule as UiEnergyModule,
    UiModalModule,
} from '@smooved/ui';
import { FileUploadModule } from 'ng2-file-upload';
import { CardModule } from '../card/card.module';
import { MetaModule } from '../meta/meta.module';
import { ModalModule } from '../modal/modal.module';
import { SharedModule } from '../shared/shared.module';
import { EnergyModule as WizardEnergyModule } from '../wizard/energy/energy.module';
import { EnergyStopSuppliersFormComponent } from './components/energy-stop-suppliers-form/energy-stop-suppliers-form.component';
import { EnergySuggestionSelectionComponent } from './components/energy-suggestion-selection/energy-suggestion-selection.component';
import { EnergySuggestionComponent } from './components/energy-suggestion/energy-suggestion.component';
import { EnergySuggestionsComponent } from './components/energy-suggestions/energy-suggestions.component';
import { EnergyTypeComponent } from './components/energy-type/energy-type.component';
import { MeterInfoElectricityComponent } from './components/meter-info-electricity/meter-info-electricity.component';
import { MeterInfoGasComponent } from './components/meter-info-gas/meter-info-gas.component';
import { MeterInfoStateComponent } from './components/meter-info-state/meter-info-state.component';
import { MeterInfoComponent } from './components/meter-info/meter-info.component';
import { MeterReadingElectricityInputComponent } from './components/meter-reading-electricity-input/meter-reading-electricity-input.component';
import { MeterReadingElectricityComponent } from './components/meter-reading-electricity/meter-reading-electricity.component';
import { MeterReadingGasInputComponent } from './components/meter-reading-gas-input/meter-reading-gas-input.component';
import { MeterReadingGasComponent } from './components/meter-reading-gas/meter-reading-gas.component';
import { MeterReadingsComponent } from './components/meter-readings/meter-readings.component';
import { ShowPromoInfoComponent } from './components/show-promo-info/show-promo-info.component';
import { SolarPanelComponent } from './components/solar-panel/solar-panel.component';
import { ZipCodeComponent } from './components/zip-code/zip-code.component';
import { EnergyOfferSelectedContainer } from './containers/energy-offer-selected/energy-offer-selected.container';
import { EnergySuggestionsContainer } from './containers/energy-suggestions/energy-suggestions.container';
import { EnergyTypeContainer } from './containers/energy-type/energy-type.container';
import { FamilySizeContainer } from './containers/family-size/family-size.container';
import { HomeConstructionYearContainer } from './containers/home-construction-year/home-construction-year.container';
import { HomeDescriptionContainer } from './containers/home-description/home-description.container';
import { MeterInfoContainer } from './containers/meter-info/meter-info.container';
import { MeterTypeContainer } from './containers/meter-type/meter-type.container';
import { ZipCodeContainer } from './containers/zip-code/zip-code.container';
import { EnergyRoutingModule } from './energy-routing.module';
import { AdminMeterInfoModal } from './modals/admin-meter-info/admin-meter-info.modal';
import { EnergyMetersInfoModal } from './modals/energy-meters-info/energy-meters-info.modal';
import { EnergyPromoModal } from './modals/energy-promo/energy-promo.modal';
import { FilePreviewModal } from './modals/file-preview/file-preview.modal';
import { MeterInfoSuccessComponent } from './modals/meter-info-success/meter-info-success.component';
import { MeterReadingElectricityModal } from './modals/meter-reading-electricity/meter-reading-electricity.modal';
import { MeterReadingGasModal } from './modals/meter-reading-gas/meter-reading-gas.modal';

const pipes = [ProductConditionPipe];

const components = [
    EnergySuggestionSmallComponent,
    MeterReadingElectricityInputComponent,
    MeterReadingGasInputComponent,
    MeterReadingElectricityComponent,
    MeterReadingElectricityModal,
    MeterInfoComponent,
    MeterReadingGasModal,
    MeterReadingGasComponent,
    EnergySuggestionSelectionComponent,
    EnergyTypeComponent,
    MeterTypeComponent,
    ZipCodeComponent,
    EnergySuggestionsComponent,
    EnergyPromoModal,
    ServiceEnergyMeterReadingsComponent,
    MeterInfoElectricityComponent,
    MeterInfoGasComponent,
    EanInfoModal,
    MeterInfoContainer,
    EnergyStopSuppliersFormComponent,
    EnergyOrderInformationComponent,
    TakeoverInformationConsentComponent,
    MeterReadingsTakeoverDoneComponent,
    MeterReadingsComponent,
    MeterInfoStateComponent,
    FilePreviewModal,
];

@NgModule({
    declarations: [
        ...components,
        ...pipes,
        AdminMeterInfoModal,
        EnergySuggestionComponent,
        ShowPromoInfoComponent,
        ZipCodeContainer,
        FamilySizeContainer,
        HomeConstructionYearContainer,
        HomeDescriptionContainer,
        EnergyTypeContainer,
        MeterTypeContainer,
        EnergySuggestionsContainer,
        EnergyOfferSelectedContainer,
        SolarPanelComponent,
        EanInfoTableActionsColumnComponent,
        EanInfoTableResultColumnComponent,
        MeterInfoSuccessComponent,
        EnergyMetersInfoModal,
    ],
    imports: [
        EnergyRoutingModule,
        MatButtonModule,
        ModalModule,
        UiModalModule,
        SharedModule,
        CardModule,
        MetaModule,
        WizardEnergyModule,
        SvgModule,
        TranslationModule,
        AlertModule,
        CorePipeModule,
        InfoModule,
        TrackAndTraceModule,
        DocumentCenterModule,
        TableModule,
        UiEnergyModule,
        FileUploadModule,
    ],
    exports: [...components],
})
export class EnergyModule {}
