import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MeterInfoComponent } from '@app/energy/components/meter-info/meter-info.component';
import { adminMeterInfoForm } from '@app/energy/modals/admin-meter-info/admin-meter-info.constants';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { FeaturePermission } from '@app/feature-scope/sandboxes/feature-permission.interface';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { RealEstateAgentService } from '@app/real-estate-agent/services/real-estate-agent.service';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { EnergyStop } from '@app/wizard/energy/interfaces/energy-stop';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils, EnergyMetersState, FeatureScope, Role } from '@smooved/core';
import { ModalSandbox, NotificationSandbox } from '@smooved/ui';
import { SelectInput, SelectInputVariant } from 'projects/ui/src/lib/form/select-input/select-input';
import { startWith, take, takeUntil } from 'rxjs/operators';
import {
    defaultMeterInputRole,
    MeterInfoAlertType,
    meterInfoInputOptions,
    MeterInfoInputType,
    meterInputRoleOptions,
    mostChosenI18nKey,
} from './meter-info.constants';

@Component({
    selector: 'app-meter-info-container',
    templateUrl: './meter-info.container.html',
    styleUrls: ['./meter-info.container.scss'],
})
export class MeterInfoContainer extends MeterInfoComponent implements OnInit {
    @Input() public move: Move;
    @Input() public hideSubmit = false;
    @Input() public electricity: boolean;
    @Input() public gas: boolean;

    public readonly meterInfoInputType = MeterInfoInputType.Manual;
    public readonly meterInputRole = new UntypedFormControl(defaultMeterInputRole);
    public readonly meterInputRoles = Role;
    public readonly meterInfoForm = adminMeterInfoForm;
    public readonly meterInfoInputOptions = meterInfoInputOptions;
    public readonly selectInputVariants = SelectInputVariant;
    public readonly meterInfoAlertTypes = MeterInfoAlertType;

    public meterInputRoleOptions: SelectInput<Role>[];
    public meterReadingMaxDate = DateUtils.now();
    public meterReadingDisabled: boolean;

    public leaverMeterCollectionfeatureScopePermission: FeaturePermission;

    constructor(
        public authenticationSandbox: AuthenticationSandbox,
        protected modalSandbox: ModalSandbox,
        protected uiSandbox: AppUiSandbox,
        protected notificationSandbox: NotificationSandbox,
        protected moveSandbox: MoveSandbox,
        protected fb: UntypedFormBuilder,
        protected translateService: TranslateService,
        protected readonly realEstateAgentService: RealEstateAgentService,
        private readonly cdr: ChangeDetectorRef,
        private readonly featurePermissionSandbox: FeaturePermissionsSandbox
    ) {
        super(
            authenticationSandbox,
            modalSandbox,
            uiSandbox,
            notificationSandbox,
            moveSandbox,
            fb,
            translateService,
            realEstateAgentService
        );
    }

    public ngOnInit(): void {
        this.data = {
            move: this.move,
            readOnly: [EnergyMetersState.Confirmed, EnergyMetersState.Processed].includes(
                this.move?.track?.energyMeterReadings?.energyMetersState
            ),
            asTransactionType: this.move?.moveStates.transactionType,
        };

        super.ngOnInit();

        this.featurePermissionSandbox
            .getPermissions$(FeatureScope.LeaverMeterCollection, this.move)
            .pipe(take(1))
            .subscribe((permission) => {
                this.leaverMeterCollectionfeatureScopePermission = permission;
                this.meterInputRole.setValue(permission.canExecute ? Role.Mover : Role.RealEstateAgent);
            });

        this.form
            .get(adminMeterInfoForm.MovingDate)
            .valueChanges.pipe(startWith(this.move.movingDate), takeUntil(this.destroy$))
            .subscribe(this.handleMovingDateChanged);

        if (this.data.readOnly) {
            this.form.disable();
        }

        this.meterInputRole.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((role: Role) => {
            if (role === Role.RealEstateAgent) this.form.setErrors(null);
            else this.form.updateValueAndValidity();
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;

        const meterReadingCtrl = this.form.get(adminMeterInfoForm.MeterReadingDate);
        if (meterReadingCtrl.enabled && !meterReadingCtrl.value) {
            meterReadingCtrl.patchValue(DateUtils.today());
        }
        this.uiSandbox.showLoadingOverlay();

        super.onSubmit();
    }

    protected getPatchData(): Move {
        const movingDate = this.form.get(adminMeterInfoForm.MovingDate).value as Date;

        const moverInput = this.isInputByMover()
            ? {
                  ...(this.meterInfoElectricityComponent?.createPatch() || {}),
                  ...(this.meterInfoGasComponent?.createPatch() || {}),
                  meterReadingDate: this.form.get(adminMeterInfoForm.MeterReadingDate).value as Date,
              }
            : {};

        return {
            ...moverInput,
            movingDate,
        };
    }

    private handleMovingDateChanged = (value: Date): void => {
        this.enableMeterReadings(!this.data.readOnly && EnergyUtils.canEditMeterReadings(value));
    };

    private enableMeterReadings(enabled: boolean): void {
        enabled ? this.meterInputRole.enable() : this.meterInputRole.disable();
        this.meterReadingDisabled = !enabled;
        this.form.get(adminMeterInfoForm.MeterReadingDate)[enabled ? 'enable' : 'disable']();
        this.form.get(adminMeterInfoForm.MeterReadingDate).patchValue(enabled ? this.move.meterReadingDate || DateUtils.today() : null);
        this.setMeterInputgRoleOptions();
        this.cdr.detectChanges();
    }

    private setMeterInputgRoleOptions(): void {
        this.meterInputRoleOptions = meterInputRoleOptions(!this.move?.moveStates.metersProcessedByAdmin && this.meterReadingDisabled);
        this.meterInputRoleOptions.forEach((option) => {
            if (option.value === this.meterInputRole.value) option.tag = mostChosenI18nKey;
        });
    }

    private isInputByMover(): boolean {
        return this.meterInputRole.value === Role.Mover;
    }
}
