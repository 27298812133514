import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RoomDivision } from '@app/move/interfaces/room-division.interface';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { isNumber } from 'lodash';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-home-division',
    template: `
        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit()">
            <app-number-input
                formControlName="bedrooms"
                [label]="'MOVE.META.ROOM_DIVISION.BEDROOMS_COUNT.LABEL' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
            ></app-number-input>

            <h6 class="u-color-muted u-margin-bottom">{{ 'MOVE.META.ROOM_DIVISION.STANDARD_ROOMS.LABEL' | translate }}</h6>
            <div class="__input-container u-margin-bottom-double">
                <app-number-input
                    formControlName="bathrooms"
                    [label]="'MOVE.META.ROOM_DIVISION.BATHROOMS.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
                <app-number-input
                    formControlName="livingRooms"
                    [label]="'MOVE.META.ROOM_DIVISION.LIVING_ROOMS.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
                <app-number-input
                    formControlName="kitchens"
                    [label]="'MOVE.META.ROOM_DIVISION.KITCHENS.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
            </div>

            <h6 class="u-color-muted u-margin-bottom">{{ 'MOVE.META.ROOM_DIVISION.EXTRA_ROOMS.LABEL' | translate }}</h6>
            <div class="__input-container u-margin-bottom">
                <app-number-input
                    formControlName="basements"
                    [label]="'MOVE.META.ROOM_DIVISION.BASEMENTS.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
                <app-number-input
                    formControlName="attics"
                    [label]="'MOVE.META.ROOM_DIVISION.ATTICS.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
                <div>
                    <app-number-input
                        formControlName="parkingSpotsInGarage"
                        [label]="'MOVE.META.ROOM_DIVISION.PARKING_SPOTS_IN_GARAGE.LABEL' | translate"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                    ></app-number-input>
                    <p class="u-color-muted u-font-size-small u-font-style-italic u-margin-top-half">
                        {{ 'MOVE.META.ROOM_DIVISION.PARKING_SPOTS_IN_GARAGE.DESCRIPTION' | translate }}
                    </p>
                </div>
            </div>
            <div class="__input-container u-margin-bottom-double">
                <app-number-input
                    formControlName="verandas"
                    [label]="'MOVE.META.ROOM_DIVISION.VERANDAS.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
                <div>
                    <app-number-input
                        formControlName="otherRoomsLargerThanFourSquaredMeter"
                        [label]="'MOVE.META.ROOM_DIVISION.OTHER_ROOMS_LARGER_THAN_FOUR_SQUARED_METER.LABEL' | translate"
                        [hasMargin]="false"
                        [hasMarginDouble]="false"
                    ></app-number-input>
                    <p class="u-color-muted u-font-size-small u-font-style-italic u-margin-top-half">
                        {{ 'MOVE.META.ROOM_DIVISION.OTHER_ROOMS_LARGER_THAN_FOUR_SQUARED_METER.DESCRIPTION' | translate }}
                    </p>
                </div>
                <app-number-input
                    formControlName="outhouseLargerThanTenSquaredMeter"
                    [label]="'MOVE.META.ROOM_DIVISION.OUTHOUSE_LARGER_THAN_TEN_SQUARED_METER.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                ></app-number-input>
            </div>

            <app-previous-submit (previous)="onPrevious()"></app-previous-submit>
        </form>
    `,
    styleUrls: ['./room-division.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDivisionComponent implements OnInit {
    @Output() public previous: EventEmitter<RoomDivision> = new EventEmitter<RoomDivision>();
    @Output() public next: EventEmitter<RoomDivision> = new EventEmitter<RoomDivision>();

    public form: UntypedFormGroup = this.formBuilder.group({
        bedrooms: [0, Validators.min(0)],
        livingRooms: [1, Validators.min(0)],
        kitchens: [1, Validators.min(0)],
        bathrooms: [1, Validators.min(0)],
        verandas: [0, Validators.min(0)],
        attics: [0, Validators.min(0)],
        basements: [0, Validators.min(0)],
        parkingSpotsInGarage: [0, Validators.min(0)],
        otherRoomsLargerThanFourSquaredMeter: [0, Validators.min(0)],
        outhouseLargerThanTenSquaredMeter: [0, Validators.min(0)],
    });

    constructor(private formBuilder: UntypedFormBuilder, private moveSandbox: MoveSandbox) {}

    public ngOnInit(): void {
        this.moveSandbox.metaOnce$
            .pipe(
                filter((meta) => !!meta?.roomDivision && !!Object.values(meta.roomDivision)?.length),
                map((meta) => meta?.roomDivision)
            )
            .subscribe((roomDivision) => {
                const payload: RoomDivision = {};
                for (const key in roomDivision) {
                    if (roomDivision.hasOwnProperty(key) && isNumber(roomDivision[key])) {
                        payload[key] = roomDivision[key];
                    }
                }
                this.form.patchValue(payload);
            });
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.next.emit(this.createPatch());
        }
    }

    public onPrevious(): void {
        this.previous.emit(this.createPatch());
    }

    private createPatch(): RoomDivision {
        return this.form.value;
    }
}
