import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnergyOfferSelectedContainer } from './containers/energy-offer-selected/energy-offer-selected.container';
import { EnergySuggestionsContainer } from './containers/energy-suggestions/energy-suggestions.container';
import { EnergyTypeContainer } from './containers/energy-type/energy-type.container';
import { FamilySizeContainer } from './containers/family-size/family-size.container';
import { HomeConstructionYearContainer } from './containers/home-construction-year/home-construction-year.container';
import { HomeDescriptionContainer } from './containers/home-description/home-description.container';
import { MeterTypeContainer } from './containers/meter-type/meter-type.container';
import { ZipCodeContainer } from './containers/zip-code/zip-code.container';

const routes: Routes = [
    {
        path: 'data/zip-code',
        component: ZipCodeContainer,
    },
    {
        path: 'data/family-size',
        component: FamilySizeContainer,
    },
    {
        path: 'data/home-description',
        component: HomeDescriptionContainer,
    },
    {
        path: 'data/home-construction-year',
        component: HomeConstructionYearContainer,
    },
    {
        path: 'data/energy-type',
        component: EnergyTypeContainer,
    },
    {
        path: 'data/meter-type',
        component: MeterTypeContainer,
    },
    {
        path: 'energy-suggestions',
        component: EnergySuggestionsContainer,
    },
    {
        path: 'offer-selected',
        component: EnergyOfferSelectedContainer,
    },
    {
        path: '**',
        redirectTo: 'data/zip-code',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EnergyRoutingModule {}
